import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import AuthButtons from '../components/AuthButtons';

const HomePage = () => {
  const [originalUrl, setOriginalUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [result, setResult] = useState({ text: '', type: '' }); // 用于显示结果

  const handleShorten = () => {
    // 模拟API调用
    setResult({
      text: 'google.com',
      type: 'shortened'
    });
  };

  const handleReverse = () => {
    // 模拟API调用
    setResult({
      text: 'https://www.google.com/',
      type: 'original'
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(result.text)
      .then(() => {
        // 临时显示复制成功提示
        const originalText = result.text;
        setResult(prev => ({ ...prev, text: 'Copied!' }));
        setTimeout(() => {
          setResult(prev => ({ ...prev, text: originalText }));
        }, 1000);
      });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <Sidebar />
      <AuthButtons />
      
      <main className="flex-1 ml-16 flex flex-col items-center justify-center min-h-screen">
        <div className="w-full max-w-3xl px-4 -mt-20">
          {/* Logo */}
          <h1 className="text-6xl font-bold text-center text-gray-700 mb-16 drop-shadow-md">
            Shortify
          </h1>
          
          {/* URL Input sections */}
          <div className="space-y-6 mb-8"> {/* 减小下边距 */}
            <div className="flex w-full">
              <input
                type="text"
                placeholder="Enter Original Link Here"
                value={originalUrl}
                onChange={(e) => setOriginalUrl(e.target.value)}
                className="flex-1 p-4 text-lg border border-gray-200 rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button 
                onClick={handleShorten}
                className="px-8 py-4 bg-gray-100 text-gray-700 border border-l-0 border-gray-200 rounded-r hover:bg-gray-200 transition-colors"
              >
                Shorten !
              </button>
            </div>
            
            <div className="flex w-full">
              <input
                type="text"
                placeholder="Enter Shortened Link Here"
                value={shortUrl}
                onChange={(e) => setShortUrl(e.target.value)}
                className="flex-1 p-4 text-lg border border-gray-200 rounded-l focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button 
                onClick={handleReverse}
                className="px-8 py-4 bg-gray-100 text-gray-700 border border-l-0 border-gray-200 rounded-r hover:bg-gray-200 transition-colors"
              >
                Reverse !
              </button>
            </div>
          </div>

          {/* 结果显示区域 */}
          {result.text && (
            <div className="mb-16 p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-sm text-gray-500 mb-1">
                    {result.type === 'shortened' ? 'Shortened URL:' : 'Original URL:'}
                  </p>
                  <p className="text-lg text-gray-700 font-medium">{result.text}</p>
                </div>
                <button
                  onClick={handleCopy}
                  className="px-4 py-2 text-sm text-blue-600 hover:bg-blue-50 rounded-md transition-colors"
                >
                  Copy
                </button>
              </div>
            </div>
          )}

          {/* Info sections */}
          <div className="grid grid-cols-2 gap-8 text-gray-600">
            <div>
              <h2 className="text-2xl mb-4">Why choose us?</h2>
              <ul className="space-y-2">
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Fast and reliable service</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Secure link management</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Easy to use interface</span>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="text-2xl mb-4">Disclaimer:</h2>
              <ul className="space-y-2">
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Links expire after 30 days</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>No illegal content allowed</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Use at your own risk</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;