import React from 'react';
import { Link } from 'react-router-dom';

export const AuthButtons = () => {
  return (
    <div className="absolute top-4 right-4 space-x-4">
      <Link to="/login" className="px-6 py-2 text-gray-600 hover:bg-gray-100 rounded-md border border-gray-300 transition-colors">
        Log in
      </Link>
      <Link to="/login" className="px-6 py-2 text-gray-600 hover:bg-gray-100 rounded-md border border-gray-300 transition-colors">
        Sign up
      </Link>
    </div>
  );
};

export default AuthButtons;