import React, { useState } from 'react';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // 模拟API调用
    setTimeout(() => {
      console.log('Login attempt with:', { email, password });
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center">
      {/* Logo */}
      <div className="mb-8">
        <h1 className="text-4xl font-bold text-gray-700 mb-2 text-center">
          Shortify
        </h1>
        <p className="text-gray-500">URL Shortener Service</p>
      </div>

      {/* Login Form */}
      <div className="w-full max-w-md bg-white rounded-lg shadow-sm border border-gray-200">
        <form onSubmit={handleSubmit} className="p-8">
          {/* Email Input */}
          <div className="mb-6">
            <label 
              htmlFor="email" 
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
              placeholder="your@email.com"
              required
            />
          </div>

          {/* Password Input */}
          <div className="mb-6">
            <label 
              htmlFor="password" 
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
              placeholder="••••••••"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full py-3 px-4 rounded-md text-white font-medium 
              ${loading 
                ? 'bg-blue-400 cursor-not-allowed' 
                : 'bg-blue-500 hover:bg-blue-600'} 
              transition-colors`}
          >
            {loading ? 'Processing...' : 'Log in / Sign up'}
          </button>

          {/* Auto-register notice */}
          <p className="mt-4 text-center text-sm text-gray-500">
            New users will be automatically registered
          </p>
        </form>
      </div>

      {/* Back to home link */}
      <a 
        href="/"
        className="mt-8 text-sm text-gray-500 hover:text-gray-700 transition-colors"
      >
        ← Back to home
      </a>
    </div>
  );
};

export default LoginPage;