import React from 'react';

export const Sidebar = () => {
  return (
    <div className="fixed left-0 top-0 h-full w-16 bg-white border-r border-gray-200">
      <button className="w-full p-4 hover:bg-gray-100 transition-colors">
        <div className="text-3xl">+</div>
      </button>
    </div>
  );
};

export default Sidebar;